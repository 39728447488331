import "./Footer.css";
export default function Footer() {
  return (
    <footer className="App-footer">
      <div className="Footer-text-wrapper">
        <img src={"uio-logo-no.svg"} alt="University of Oslo" />
        <p>
          Contact us: &nbsp;
          <a href="mailto:lorenztk@uio.no">lorenztk@uio.no</a>
        </p>
      </div>
    </footer>
  );
}
