import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { Suspense, lazy } from "react";
import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";
import { Flex, Button } from "@chakra-ui/react";
import { FaArrowDown, FaArrowRight } from "react-icons/fa";

const OnboardingStep = lazy(() => import("../components/OnboardingStep"));

export default function Home() {
  const handleNavigate = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const onboardingRef = useRef(null);

  const scrollToOnboarding = () => {
    onboardingRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="Top-ledger">
          <a href="/">
            <img
              src={"meshr_full_logo.png"}
              className="Top-ledger-logo"
              alt="Top left logo"
            />
          </a>
          <div className="Right-ledger">
            <a href="/about" className="Top-ledger-link">
              About the project
            </a>
          </div>
        </div>
      </header>

      <img src={"purple_blob.png"} className="Purple-blob" alt="Purple blob" />
      <div className="Content-wrapper-top">
        <br />
        <h1>
          Unlock the power of citations within human rights case law and
          <span style={{ color: "#235992" }}>
            {" "}
            streamline your legal research
          </span>
        </h1>
        <p>
          With network analysis and AI-powered search, MESHR is a web
          application that makes ECtHR case law more accessible and engaging. It
          allows you to explore case law from the European Court of Human Rights
          (ECtHR) in a way that is both intuitive and informative.
          <br />
          <br />
          <div className="About-link">
            <a href="/about">
              <FaArrowRight size={12} /> Read more about the project
            </a>
          </div>
        </p>
        <Button
          className="App-button App-button"
          onClick={() => handleNavigate("https://staging.meshr.org")}
        >
          Start your human rights research journey
        </Button>
        <div className="Content-wrapper-cell">
          <p>In collaboration with and funded by</p>
          <a href="https://www.jus.uio.no/cell/">
            CELL: Center on Experiential Legal Learning
          </a>
        </div>
      </div>

      <div className="Content-wrapper-bottom">
        <div className="Scroll-indicator">
          <h2>Scroll to see how it works</h2>
          <Button
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
            onClick={scrollToOnboarding}
            className="Scroll-button"
          >
            <FaArrowDown className="Scroll-arrow" marginTop={5} size={20} />
          </Button>
        </div>
        <div className="Onboarding-steps-container" ref={onboardingRef}>
          <div className="Description-box">
            <Suspense fallback={<p>Loading...</p>}>
              <AnimatedOnboardingStep
                description={
                  <>
                    <strong>1.</strong> Use your research page with previously
                    viewed and recommended cases, and begin your research by
                    opening a case in a <strong>graph</strong>
                  </>
                }
                videoUrl="1.Research_page.mov"
              />
            </Suspense>
            <Suspense fallback={<p>Loading...</p>}>
              <AnimatedOnboardingStep
                description={
                  <>
                    <strong>2.</strong> ...or <strong>search</strong> for a case
                    name or case contents and get similar cases suggested to you
                  </>
                }
                videoUrl="2.Case_search.mov"
              />
            </Suspense>
            <Suspense fallback={<p>Loading...</p>}>
              <AnimatedOnboardingStep
                description={
                  <>
                    <strong>3.</strong> See how the selected case{" "}
                    <strong>connects</strong> to other cases through direct
                    citations and case contents
                    <br />
                  </>
                }
                videoUrl="3.Connections.mov"
              />
            </Suspense>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

function AnimatedOnboardingStep({ description, videoUrl }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
      }}
      transition={{ duration: 0.8 }}
    >
      <OnboardingStep description={description} videoUrl={videoUrl} />
    </motion.div>
  );
}
