import "./About.css";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import dan from "../assets/dan.jpg";
import haakon from "../assets/haakon.jpg";
import inger from "../assets/inger.jpg";
import lorenz from "../assets/lorenz.jpeg";
import nasjida from "../assets/nasjida.jpg";
import eric from "../assets/eric.jpg";
import Footer from "../components/Footer";
import { FaArrowLeft } from "react-icons/fa";

function About() {
  const handleNavigate = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="Top-ledger">
          <a href="/">
            <img
              src={"meshr_full_logo.png"}
              className="Top-ledger-logo"
              alt="Top left logo"
            />
          </a>
          <div className="Right-ledger">
            <a href="/" className="Top-ledger-link">
              <FaArrowLeft size={12} /> Go back home
            </a>
          </div>
        </div>
      </header>
      <img src={"purple_blob.png"} className="Purple-blob" alt="Purple blob" />
      <div className="About-container">
        <div className="Description-box">
          <h1 className="About-title"> About the project</h1>
          <p>
            MESHR is an innovative tool designed to transform how legal
            professionals, students, and researchers navigate and learn from
            human rights case law. By combining cutting-edge network analysis,
            AI-recommended cases, contextual search, and interactive graph
            visualizations, MESHR helps users explore case law from the European
            Court of Human Rights (ECtHR).
            <br />
            <br />
            <br />
            MESHR simplifies the complexities of legal precedents and enables
            the creation of personalized learning paths for a deeper
            understanding of human rights case law.
            <br />
            <br />
            <h2 className="About-subheading">Our Journey</h2>
            MESHR began as a winning solution in the European TJENI Hackathon,
            hosted by the Council of Europe in 2023. The project has grown under
            the support of the Center on Experiential Legal Learning (CELL) at
            the University of Oslo.
            <br />
            <br />
            Since its official development launch in early 2024, MESHR has
            undergone continuous improvement based on feedback from users and
            stakeholders.
            <br />
            <br />
            <h2 className="About-subheading">Our Vision</h2>
            We aim to expand MESHR to include more datasets and advanced
            functionality, making it an indispensable tool for legal education
            and research. <br />
            <br />
            By prioritizing accessibility, innovation, and user-centered design,
            MESHR seeks to make legal knowledge more interactive and inclusive.
            <br />
          </p>
        </div>
        <div className="Content-wrapper-about">
          <h1 className="team-heading">
            Meet the <strong>MESHR</strong> team
          </h1>
          <div className="team-container">
            <div className="team-member">
              <img
                src={inger}
                alt="Inger Marie Liepelt"
                className="team-member-photo"
              />
              <h2 className="team-member-name">
                Inger Marie <br /> Liepelt
              </h2>
              <i>Co-founder and interaction designer</i>
            </div>
            <div className="team-member">
              <img
                src={lorenz}
                alt="Lorenz T. Keefer"
                className="team-member-photo"
              />
              <h2 className="team-member-name">Lorenz Keefer</h2>
              <i>Co-founder and tech lead</i>
            </div>
            <div className="team-member">
              <img
                src={haakon}
                alt="Håkon Liltved Hyrve"
                className="team-member-photo"
              />
              <h2 className="team-member-name">Håkon Liltved Hyrve</h2>
              <i>Data scientist</i>
            </div>
            <div
              className="team-member"
              onClick={() =>
                window.open("https://www.linkedin.com/in/nasjida-n/", "_blank")
              }
            >
              <img
                src={nasjida}
                alt="Nasjida Noorestany"
                className="team-member-photo"
              />
              <h2 className="team-member-name">Nasjida Noorestany</h2>
              <i>Co-founder, law student and project development advisor</i>
            </div>
            <div className="team-member">
              <img src={dan} alt="Dan Uehara" className="team-member-photo" />
              <h2 className="team-member-name">Dan Uehara</h2>
              <i>Co-founder, advisor and PhD: Experiential Legal Learning</i>
            </div>
            <div className="team-member">
              <img
                src={eric}
                alt="Eric Svebakk"
                className="team-member-photo"
              />
              <h2 className="team-member-name">Eric Svebakk</h2>
              <i>Full-stack developer</i>
            </div>
          </div>
          <div className="faq">
            <h2 className="faq-heading">Frequently asked questions</h2>

            <details>
              <summary>Who can use MESHR?</summary>
              <p>
                MESHR is designed for law students, researchers, legal
                professionals, and anyone interested in human rights case law.
              </p>
            </details>
            <details>
              <summary>Is MESHR free to use?</summary>
              <p>
                Yes, MESHR is free to use by registering on{" "}
                <a href="https://staging.meshr.org">staging.meshr.org</a>. MESHR
                is a non-profit project, and we are committed to making our tool
                available to everyone.
              </p>
            </details>
            <details>
              <summary>What cases are included in MESHR?</summary>
              <p>
                Currently, MESHR provides access to case law from the European
                Court of Human Rights (ECHR), with plans to expand to other
                datasets.{" "}
                <strong>
                  Please note that we do not include cases that have not met the
                  admissibility criteria.
                </strong>
              </p>
            </details>
            <details>
              <summary>How does MESHR handle my personal data?</summary>
              <p>
                Your data is safe with us. All data is handled in a secure
                manner based on our privacy policy and in accordance with the
                GDPR. We do not use any 3rd party APIs. All data is processed on
                our own servers here in Oslo.
              </p>
            </details>
            <details>
              <summary> Do I need to install software to use MESHR?</summary>
              <p>
                No installation is required. MESHR is a fully web-based
                application that you can access directly from your browser.
              </p>
            </details>
            <details>
              <summary>How can I get involved?</summary>
              <p>
                We are always looking for people who are interested in helping
                us improve MESHR! If you have skills in areas such as natural
                language processing, machine learning, web development, or have
                a background in law, we would love to hear from you. Please
                contact us <a href="mailto:support@meshr.org"> via our email</a>
                .
              </p>
            </details>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
